body {
    margin: 0;
    font-family: 'Montserrat', Helvetica, Arial, Verdana, Tahoma, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f2f4f7;
    color: #444;
}

.footer {
    font-size: 16px;
    line-height: 24px;
    color: #98a2b3;
    width: 100%;
    padding: 10px;
    height: 50px;
    text-align: center;
}

.nc_list {
    z-index: 1500 !important;
}
